import React from 'react';
import { Col, Container, Image, Row } from "react-bootstrap"
import { BsArrowReturnLeft } from "react-icons/bs"

function ProductDetail({ name, image, detail }) {

    const hasPoints = (array) => {
        if (array.length > 1) {
            return true
        } else return false
    }

    function display(data) {
        if (hasPoints(data)) {
          return (
              <ul>
                  {data.map(point => (
                      <li>- {point}</li>
                  ))}
              </ul>
          )
      } else return <p>{data}</p>
    }

    return ( 
        <section className="" style={{ backgroundColor: "white", marginTop: '4rem', marginBottom: '4rem' }}>
          <Container>
            <Row>
              <Col md={5}>
                <Image src={image} fluid />
              </Col>

              {/* Name */}
              <Col
                style={{
                  backgroundColor: "white",
                  color: "black",
                  borderRight: "3px solid red",
                }}
                className="pt-2"
                md={2}
              >
                <h2 className="heading">{name}</h2>
                <BsArrowReturnLeft size={50} style={{}} className="mt-3" />
              </Col>

              {/* Detail */}
              <Col md={5}>
                  {console.log("Has Points:- "+ hasPoints(detail.about))}
                  {display(detail.about)}
                <h4>Advantages</h4>
                {display(detail.advantages)}
              </Col>
            </Row>
          </Container>
        </section>
     );
}

export default ProductDetail;