import React from "react"
import { Container, Table } from "react-bootstrap"

function TechnicalData({ techData }) {
  return (
    <section className="pt-5 pb-5" style={{ backgroundColor: "brown" }}>
      <Container>
        <h2 className="text-center mb-4 text-white">Technical Data</h2>
        <hr style={{ backgroundColor: "white", opacity: "1" }} />
        <Table striped bordered responsive style={{ backgroundColor: "white" }}>
          <thead>
            <tr>
              <th>Particular</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {techData.column1.map((col, i) => (
              <tr>
                <td>{col}</td>
                <td>{techData.column2[i]}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </section>
  )
}

export default TechnicalData
