import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import multi_seater_toilet from "../../public/static/images/products/multi_seater_toilet.jpg"

function MultiSeaterToilet() {

  const prodDetail = {
    "name": "MULTI SEATER",
    "detail": {
      "about" : ["Being a quality-centric company, we are engaged in offering a superior quality Mobile Toilet Van 10, 8, 6, 4 and 2 Seater. Our offered van is precisely manufactured using modern technology by our team of qualified professionals who have vast knowledge & experienced."],
      "advantages": [
        "Sturdiness",
        "Easy to use",
        "Low maintenance",
        "6 seater",
        "8 seater",
        "10 seater"
      ]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Seater",
        "Sheet Material",
        "Thickness",
        "Sludge Tank Capacity",
        "Water Tank Capacity",
        "Size",
        "Arrangement",
        "Facilities"
      ],
      "column2": [
        "IEMS / IEMSS",
        "4, 6, 8, 10 Seats or Customized",
        "M.S. Galvanized Iron, Stainless Steel, FRP Etc.",
        "1 mm, 1.6 mm, 2 mm, 2.5 mm, 3 mm or can be Customized as per requirement.",
        "500 ltres. or can be Customized as per requirement.",
        "100 ltres. or can be Customized as per requirement.",
        "920 x 975 x 2130 mm or can be Customized as per requirement.",
        "Provision for Both Men & Women with fully furnished Public service at fairs.",
        "Proper Ventilation, Exhaust Fans, Superior Finishing with facilities like Mirror, Soap, Tray Towel, Rod, Washbasin, Handle with Locks etc."
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={multi_seater_toilet} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default MultiSeaterToilet
